let header = {
    "APP-LONGITUDE": 32.11132131,//经度
    "APP-LATITUDE": 111.13213131,//维度
    "APP-CITY-CODE": 161,//城市编号
    "APP-CHANNEL": 1000,//渠道标识
    "APP-PLATFORM": "web", //请求端平台 ios android h5 web pc
    "APP-VERSION": "1.1.2",//版本号
    "APP-LANG": "zh_CN",//语言
    "APP-LANG-AREA": "CN", //所在地区
    "APP-DEVICEID": "", //唯一ID，指纹
    'Content-Type': 'application/json;charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest',
}

function GetRequest() {
    let url = location.search; //获取url中"?"符后的字串
    let theRequest = new Object();
    if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
}
function getUa(){
    if(navigator.userAgent.indexOf("electron") === -1){
        header["APP-PLATFORM"] = "web";
    }else{
        header["APP-PLATFORM"] = "pc";
    }
}
function getChannel() {
    const urlPara = GetRequest();
    if(urlPara.channelId){
        header["APP-CHANNEL"] = urlPara.channelId;
    }
    if (urlPara.qhclickid) {
        header["APP-QHCLICKID"] = urlPara.qhclickid;
    }
    if (urlPara.from) {
        header["APP-FROM"] = urlPara.from;
    }
}

 function getDeviceid() {
    let userInfo = JSON.parse(window.localStorage.getItem('qkh-token'));
    if (userInfo !== undefined && userInfo !== null) {
        header["USER-ID"] = userInfo.userId;
        header["ACCESS-TOKEN"] = userInfo.accessToken;
    }
    // let DEVICEID = window.localStorage.getItem('QKH-DEVICEID');
    // if(DEVICEID){
    //     header["APP-DEVICEID"] = DEVICEID;
    // }
    // FingerprintJS.load().then(fp => {
    //     fp.get().then(result => {
    //         header["APP-DEVICEID"] = result.visitorId;
    //     });
    // });
}
getUa(); //获取ua判断是web端还是pc端
getChannel(); //获取channelId
getDeviceid();
// header[" APP-LONGITUDE"] = lng;
export default header;