<template>
  <div class="mobile-footer">
    <p>公司名称：武汉卓趣科技有限公司</p>
    <p>地址：武汉市江汉区经济开发区江旺路8号5层（红提科技企业孵化器-H7071）</p>
    <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">互联网ICP备案：鄂ICP备2022009216号-1</a></p>
    <p>
    <span class="wContainer">
      <img alt="" class="wenwangwen" src="../../assets/imgs/pc/gongan.png" @click="openGongan">
           <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010302002491" target="_blank">公安网备案号 :42010302002491</a>
       </span>
    </p>
    <p>
    <span class="wContainer">
      <img alt="" class="wenwangwen" src="../../assets/imgs/pc/wenwangwen.png" @click="openWenwangwen">
    </span>
      <a class="a_link" href="https://static.kaiheifun.com/app/2022/wnwhjyxkz.pdf" target="_blank">
        网络文化经营许可证：鄂网文〔2022〕1954-194号</a>
    </p>
    <p>
  <span>
        <a href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%E9%84%82B2-20220735&type=xuke"
           target="_blank">增值电信业务经营许可证： 鄂B2-20220735</a>
      </span>
    </p>
    <p><a href="https://www.12377.cn/" target="_blank">中央网信办（国家互联网信息办公室）违法和不良信息举报中心</a></p>
    <p><a href="https://www.12321.cn/" target="_blank">12321网络不良与垃圾信息举报受理中心</a></p>
    <p>
      <a href="https://ts.isc.org.cn/" target="_blank">互联网信息服务投诉平台</a>
      <span>&nbsp;|&nbsp;</span>
      <a href="http://jbts.mct.gov.cn/" target="_blank">12318全国文化市场举报平台</a>
    </p>

    <!--  <p>联系方式: 18851317265</p>-->
  </div>
</template>

<script>
export default {
  name: "mobileFooter",
  methods: {
    openWenwangwen() {
      window.open("https://static.kaiheifun.com/app/2022/wnwhjyxkz.pdf");
    },
    openGongan(){
      window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010302002491");
    }
  }
}
</script>

<style scoped>
.mobile-footer {
  margin: auto 0;
  font-size: 11px;
  font-family: Microsoft YaHei;
  color: #C2C2C2;
  font-weight: 500;
}

.mobile-footer a {
  color: #C2C2C2;
  text-decoration: none;
}

.wenwangwen {
  width: 16px;
  cursor: pointer;
  float: none;
  display: inline-block;
  position: absolute;
  left: -20px;
  top: -2px;
}

.wContainer {
  position: relative;
}
</style>