<template>
  <div class="pc-footer">
    <p >友情链接：<a href="https://www.9891.com/">9891游戏服务 </a></p>
    <p>公司名称：武汉卓趣科技有限公司
      <span>&nbsp;|&nbsp;</span>
      公司地址：武汉市江汉区经济开发区江旺路8号5层（红提科技企业孵化器-H7071）
      <span>&nbsp;|&nbsp;</span>
      联系方式：0513-89119891

    </p>
    <p>
      武汉卓趣科技有限公司于2021年底成立。总部设于武汉，办公环境幽雅安静。当前公司致力于研发和发展三大业务模块：游戏社区、游戏分发、游戏公会。业务范围包括网络游戏及网络娱乐互动软件的开发和运营等，将成为中国领先的下一代互联网服务商。
    </p>
    <p>
      <a class="a_link" href="https://beian.miit.gov.cn/#/Integrated/index"
         target="_blank">互联网ICP备案：鄂ICP备2022009216号-1 &nbsp</a>
      <span>|&nbsp;</span>
      
      <span class="img-container">
         <img alt="" class="wenwangwen" src="../../assets/imgs/pc/gongan.png" @click="openGongan">
              <a class="a_link" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010302002491" target="_blank"> 公安网备案号 :42010302002491 &nbsp</a>
      </span>
      <span>|&nbsp;</span>
      <span class="img-container">
        <img alt="" class="wenwangwen" src="../../assets/imgs/pc/wenwangwen.png" @click="openWenwangwen">
      <a class="a_link" href="https://static.kaiheifun.com/app/2022/wnwhjyxkz.pdf" target="_blank">
        网络文化经营许可证：鄂网文〔2022〕1954-194号 &nbsp</a>
      </span>
      <span>|&nbsp;</span>
      <a class="a_link" href="https://static.kaiheifun.com/app/2022/zqyyzz.pdf" target="_blank">营业执照 &nbsp</a>
      <span>|&nbsp;</span>
      <span>
        <a href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%E9%84%82B2-20220735&type=xuke" target="_blank">增值电信业务经营许可证： 鄂B2-20220735</a>
      </span>
    </p>
    <p class="p3">

      <a class="a_link" href="https://ts.isc.org.cn/" target="_blank">互联网信息服务投诉平台</a>
      <span>&nbsp;|&nbsp;</span>
      <a class="a_link" href="http://jbts.mct.gov.cn/" target="_blank">12318全国文化市场举报平台</a>
      <span>&nbsp;| </span>
      <a class="a_link" href="https://www.12377.cn/" target="_blank"> 中央网信办（国家互联网信息办公室）违法和不良信息举报中心</a>
      <span>&nbsp;|&nbsp;</span>
      <a class="a_link" href="https://www.12321.cn/" target="_blank">12321网络不良与垃圾信息举报受理中心</a>
      <!--      <span>&nbsp;|&nbsp;</span>-->
      <!--     联系方式: 18851317265-->
    </p>

  </div>
</template>

<script>
export default {
  name: "PCFooter",
  methods: {
    openWenwangwen() {
      window.open("https://static.kaiheifun.com/app/2022/wnwhjyxkz.pdf");
    },
    openGongan(){
      window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010302002491");
    }
  }
}
</script>

<style scoped>
    body{
  margin: 0;
  /*网站变灰*/
/*filter: grayscale(100%);*/
}
.pc-footer {
  z-index:-9999;
  min-width:1200px;
  max-height: 100px;
  margin: auto 0;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}
.pc-footer >p{
  height: 8px;
}

.pc-footer a {
  color: #FFFFFF;
  text-decoration: none;
}

.p3 span{
  position: relative;
}
.img-container{
  position: relative;
  margin-left: 20px;
}
.wenwangwen {
  width: 20px;
  cursor: pointer;
  float: none;
  display: inline-block;
  position: absolute;
  left: -18px;
  top: 0;
}
</style>