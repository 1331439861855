import axios from 'axios'
import headerConfig from './headerConfig'
import store from "@/store";
// import router from '../router'
axios.defaults.headers = headerConfig;
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_SERVER_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
    // 在请求头中添加token
    let userInfo = JSON.parse(window.localStorage.getItem('qkh-token'));
    if (userInfo !== undefined && userInfo !== null) {
        config.headers["USER-ID"] = userInfo.userId;
        config.headers["ACCESS-TOKEN"] = userInfo.accessToken;
    }
    config.headers["APP-DEVICEID"] = store.state.deviceid;
    return config
})
axios.interceptors.response.use(res => {
    if(res.request){
        if (res.request.responseURL.indexOf('verification/index') > 0) {
            //获取图片验证码接口没有code，绕过拦截器
            return res;
        }
    }
    if (typeof res.data !== 'object') {
        // message.error('服务端错误');
        return Promise.reject(res);
    }
    if (res.data.code === 103) {
        // message.error(res.data.msg);
        return Promise.reject(res);
    }
    if (res.data.code != 0) {
        // message.error(res.data.msg);
        return Promise.reject(res);
    }
    return (res.data.data)
})

export default axios