import 'babel-polyfill';
// 引入vantUI（按需引入组件）
import '@/plugins/vant'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import 'normalize.css'  //css reset工具
import './icons'
import 'ant-design-vue/dist/antd.css';
import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo)
Vue.config.productionTip = false
Vue.prototype.$store = store;
Vue.use(router)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

