<template>
  <div class="mobile-header">
    <div class="header-bg"></div>
    <div class="mobile-logo">
      <img alt="" class="image" src="../../assets/imgs/pc/logo.png"/>
      <svg-icon class-name="mobile-logo-text" icon-class="logoText"></svg-icon>
    </div>
    <div class="recharge" @click="recharge">
    <span>
       <svg-icon class-name="diamonds-icon" icon-class="diamonds" @click="recharge"></svg-icon>
    充值
    </span>
    </div>
  </div>
</template>

<script>

export default {
  name: "mobileHeader",
  methods: {
    recharge() {
      let modal = document.getElementById("download-container");
      modal.style.display = "none";
      this.$router.push("/h5pay");
    }
  }

}
</script>

<style lang="less" scoped>
body {
  margin: 0;
  /*网站变灰*/
  /*filter: grayscale(100%);*/
}
.mobile-header {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 6.65%;
}
.header-bg {
  background: #000000 center no-repeat;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
}

.mobile-logo {
  height: 100%;
  opacity: 1;
  background-size: auto 60%;
  position: relative;
  padding-left: 1rem;
  display: flex;
  align-items:center;
  .image {
    height: 90%;
    display: inline-block;
    float: left;
    vertical-align: center;
  }
  .mobile-logo-text{
    display: inline-block;
    float: left;
    width:4rem;
    height: 100%;
    margin-left: 0.5rem;
  }
}

.recharge {
  position: absolute;
  width: 14vw;
  height: 100%;
  display: inline-block;
  right: 0;
  bottom: 0;

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #FFFFFF;
    cursor: pointer;
  }

  .diamonds-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-top: 4px;
    position: absolute;
    left: -24px;
    top: -3px;
  }
}
</style>