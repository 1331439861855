let httpConfig = {};
function getApiAddres() {
    if (process.env.NODE_ENV === 'development') {
        httpConfig.api1 = '/api1';
        httpConfig.api2 = '/api2';
        httpConfig.api3 = '/api3';
        httpConfig.api5 = '/api5';
    } else {
        let http = document.location.protocol; //返回 http: 或者 https
        switch (http) {
            case 'http:':
                httpConfig.api1 = 'http://' + process.env.VUE_APP_SERVER_URL1;
                httpConfig.api2 = 'http://' + process.env.VUE_APP_SERVER_URL2;
                httpConfig.api3 = 'http://' + process.env.VUE_APP_SERVER_URL3;
                httpConfig.api5 = 'http://' + process.env.VUE_APP_SERVER_URL5;
                return httpConfig;
            case 'https:':
                httpConfig.api1 = 'https://' + process.env.VUE_APP_SERVER_URL1;
                httpConfig.api2 = 'https://' + process.env.VUE_APP_SERVER_URL2;
                httpConfig.api3 = 'https://' + process.env.VUE_APP_SERVER_URL3;
                httpConfig.api5 = 'https://' + process.env.VUE_APP_SERVER_URL5;
                return httpConfig;

        }
    }
}
getApiAddres();
export default httpConfig